//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { PREFER_NOT_TO_ANSWER } from '~/utils/VFAParameters'

export default {
  name: 'FPCA-Table',
  data () {
    return {
      voter: {
        "identification": this.dict.C46,
        "votAdr": this.dict.C46,
        "voterClass": this.dict.C46,

        "salutation": this.dict.C46,
        "firstName": this.dict.C46,
        "middleName": this.dict.C46,
        "lastName": this.dict.C46,
        "suffix": this.dict.C46,
        "previousName": this.dict.C46,

        "dob": this.dict.C46,
        "birthDate": this.dict.C46,

        "classification": this.dict.C46,
        "ssn": this.dict.C46,
        "stateId": this.dict.C46,

        "votStreet": this.dict.C46,
        "votApt": this.dict.C46,
        "votCity": this.dict.C46,
        "votState": this.dict.C46,
        "votCounty": this.dict.C46,
        "votZip": this.dict.C46,

        "abrAdr": '',
        "fwdAdr": { A: '', formatted: [] },

        "email": this.dict.C46,
        "altEmail": this.dict.C46,
        "tel": this.dict.C46,
        "fax": this.dict.C46,
        "party": this.dict.C46,

        "recBallot": this.dict.C46,
      },

      Military: this.dict.C46,
      MilSpouse: this.dict.C46,
      IntendToReturn: this.dict.C46,
      UncertainReturn: this.dict.C46,
      NeverResided: this.dict.C46,
      Ms: this.dict.C46,
      Mrs: this.dict.C46,
      Ms: this.dict.C46,
      Mr: this.dict.C46,
      ReceiveBallotMail: this.dict.C46,
      ReceiveBallotEmail: this.dict.C46,
      ReceiveBallotFax: this.dict.C46,

      addlInfoRaw: this.dict.C46,
      signed: this.dict.C46,
      signatureDate: this.dict.C46,
    }
  },
  props: [ 'currentRequest',
           'additionalInfo',
           'date',
           'signatureImage',
           'dict',
           'parameters'
         ],
  mounted () {
    this.setupVoter ()
  },
  methods: {
    setupVoter () {
      Object.keys(this.currentRequest).forEach(key => this.voter[key] = this.currentRequest[key])

      const id = this.voter.identification ? this.voter.identification : this.dict.C46
      this.voter.previousName = this.voter.previousName.previousName ? this.voter.previousName.previousName : this.dict.C46
      this.voter.ssn = id.ssn ? id.ssn : (id.ssn4 ? id.ssn4 : (id.ssn9 ? id.ssn9 : this.dict.C46))
      this.voter.stateId = id.stateId ? id.stateId : this.dict.C46

      this.voter.votStreet = this.voter.votAdr.A ? this.voter.votAdr.A : this.dict.C46
      this.voter.votApt = this.voter.votAdr.B ? this.voter.votAdr.B : this.dict.C46
      this.voter.votCity = this.voter.votAdr.C ? this.voter.votAdr.C : this.dict.C46
      this.voter.votState = this.voter.votAdr.S ? this.voter.votAdr.S : this.dict.C46
      this.voter.votCounty = this.voter.votAdr.Y ? this.voter.votAdr.Y : this.dict.C46
      this.voter.votZip = this.voter.votAdr.Z ? this.voter.votAdr.Z : this.dict.C46

      // The fwdAdr.formatted array always contains the country.  If the street is empty,
      // then suppress the forwarding address.
      // That's because a valid address must have a non-empty street field.
      if (!this.voter.fwdAdr.A) {
        this.voter.fwdAdr.formatted = [this.dict.C46]
      }

      this.voter.tel = this.voter.tel ? this.voter.tel : this.dict.C46

      this.voter.birthDate = this.voter.dob.slice(5, 7) + ' / ' + this.voter.dob.slice(8, 10) + ' / ' + this.voter.dob.slice(0, 4)

      this.Military = this.voter.voterClass.toLowerCase() === 'military' ? this.dict.C45 : this.dict.C46
      this.MilSpouse = this.voter.voterClass.toLowerCase() === 'milspouse' ? this.dict.C45 : this.dict.C46
      this.IntendToReturn = this.voter.voterClass.toLowerCase() === 'intendtoreturn' ? this.dict.C45 : this.dict.C46
      this.UncertainReturn = this.voter.voterClass.toLowerCase() === 'uncertainreturn' ? this.dict.C45 : this.dict.C46
      this.NeverResided = this.voter.voterClass.toLowerCase() === 'neverresided' ? this.dict.C45 : this.dict.C46

      this.Miss = this.voter.salutation.toLowerCase() === 'miss' ? this.dict.C45 : this.dict.C46
      this.Mrs = this.voter.salutation.toLowerCase() === 'mrs' ? this.dict.C45 : this.dict.C46
      this.Ms = this.voter.salutation.toLowerCase() === 'ms' ? this.dict.C45 : this.dict.C46
      this.Mr = this.voter.salutation.toLowerCase() === 'mr' ? this.dict.C45 : this.dict.C46

      this.ReceiveBallotMail = this.voter.recBallot.toLowerCase() === 'mail' ? this.dict.C45 : this.dict.C46
      this.ReceiveBallotEmail = this.voter.recBallot.toLowerCase() === 'email' ? this.dict.C45 : this.dict.C46
      this.ReceiveBallotFax = this.voter.recBallot.toLowerCase() === 'fax' ? this.dict.C45 : this.dict.C46

      this.voter.party = this.voter.party!==PREFER_NOT_TO_ANSWER ? this.voter.party : this.dict.C46
      this.addlInfoRaw = this.additionalInfo ? this.additionalInfo : this.dict.C46

      this.signed = this.signatureImage ? this.dict.O27 : this.dict.C46
      this.signatureDate = this.date.slice(5, 7) + ' / ' + this.date.slice(8, 10) + ' / ' + this.date.slice(0, 4)
    },
  },
}
